import React from "react";
import Slider from "react-slick";
import './CarruselBlog.css'
import json from '../MenuPrensa/notas.json'

const noteimg = require.context('../../images/blog')

export default function CarruselBlog() {
  var settings = {
    centerMode: true,
    centerPadding: '100px',
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      }
    ]
  };
  return (
    <Slider {...settings}>
      {json.map(json => (
        <a href={'/prensa/' + json.slug} className="px-2">
          <div className="rounded-2 shadow bg-body-tertiary rounded overflow-hidden">
            <img className="w-100 " src={noteimg('./' + json.img)} alt={json.title} loading="lazy" />
            <div className="p-3 bg-body">
              <div className="fs-6 fecha-card text-start mb-2"
                style={{ color: '#a8a8a8', fontFamily: 'GothamBook' }}>{json.date}
              </div>
              <h3 className="fs-4 titulo-card text-start "
                style={{ color: '#464AFF', fontFamily: 'GothamBook' }}>{json.title}</h3>
              <p className="fs-6 parrafo-card text-start " style={{ color: '#181E20', fontFamily: 'GothamBook' }} dangerouslySetInnerHTML={{ __html: json.paragraf }}></p>
            </div>
          </div>
        </a>
      ))}
    </Slider>
  );
}