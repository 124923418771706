import React from 'react';
import { StyledFooter } from './Footer.styled';

const Footer = () => {
    return (
        <StyledFooter>
            <div className="container">
                <p className="text">Síguenos:</p>
                <nav className="box-social-network">
                    <ul>
                        <li><a href="https://www.tiktok.com/@cesaracunap" target="_blank"><i class="fa-brands fa-tiktok"></i></a></li>
                        <li><a href="https://www.instagram.com/cesaracunap/" target="_blank"><i class="fa-brands fa-instagram"></i></a></li>
                        <li><a href="https://www.facebook.com/CesarAcunaCAP" target="_blank"><i class="fa-brands fa-facebook-f"></i></a></li>
                        <li><a href="https://twitter.com/cesaracunap" target="_blank"><i class="fa-brands fa-twitter"></i></a></li>
                        <li><a href="https://www.linkedin.com/in/cesaracunap/" target="_blank"><i class="fa-brands fa-linkedin-in"></i></a></li>
                        <li><a href="https://www.youtube.com/@CesarAcunaPe" target="_blank"><i class="fa-brands fa-youtube"></i></a></li>
                    </ul>
                </nav>
            </div>
        </StyledFooter>
    )
};

export default Footer;