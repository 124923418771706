import React from 'react';
import logo from '../../images/brand/cap-light-logo.png';
import capFundacion from '../../images/brand/fundacion-blanco.png'
import './Cabecera.css'

const Cabecera = ({ titulo, subtitulo, link }) => {
  var responseReturn = "";
  if (titulo === "Fundación") {
    responseReturn = <>
      <div className="header-page d-flex align-items-end" style={{ minHeight: '400px' }}>
        <div className="container align-items-end">
          <div className="row" style={{ paddingTop: '2em', marginBottom: '-20px' }}>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item"><a href="/">Home</a></li>
                <li className="breadcrumb-item active" aria-current="page">{titulo}</li>
              </ol>
            </nav>
            <div className='justify-content-center'>
              <img loading="lazy" src={capFundacion} style={{ marginTop: '20px', width: '18rem' }}
                title="Fundación Cesar Acuña"
                alt="Fundación Cesar Acuña" />
            </div>
            <div className='justify-content-center p-5 white-p'>
              <p className='pFundation'>Mis orígenes humildes han desarrollado empatía y <br />sensibilidad por las
                necesidades de los demás</p>
            </div>
          </div>
        </div>
      </div>
    </>
  } else {
    responseReturn = <>
      <div className="header-page d-flex align-items-end" style={{ minHeight: '200px' }}>
        <div className="container align-items-end">
          <div className="col-sm-6 col-md-7 col-lg-8 col-xl-9 col-md-4 direction mx-auto py-3 py-md-5">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/">Home</a></li>
                {subtitulo
                  ?
                  <>
                    <li className="breadcrumb-item active" aria-current="page"><a href={`${link}`}>{titulo}</a></li>
                    <li className="breadcrumb-item active" aria-current="page">{subtitulo}</li>
                  </>
                  :
                  <li className="breadcrumb-item active" aria-current="page">{titulo}</li>
                }
              </ol>
            </nav>
            <h1 className="title-header d-flex align-items-center">{titulo}</h1>
          </div>
        </div>
      </div>
      <a href="/" class="logo">
        <img loading="lazy" src={logo} className='Cesar Acuña' title="Cesar Acuña" alt="Cesar Acuña" />
      </a>
    </>
  }
  return responseReturn;
}

export default Cabecera;