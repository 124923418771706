import { StyledMenu, StyledFondoMenu } from './Menu.styled';
import { bool } from 'prop-types';

const Menu = ({ open }) => {
  return (
    <div style={{ position: 'absolute', top: 0 }}>
      <StyledFondoMenu open={open}></StyledFondoMenu>
      <StyledMenu open={open}>
        <a href="/biografia">
          <span role="img" aria-label="biografía"></span>
          Biografía
        </a>
        <a href="/mis-obras">
          <span role="img" aria-label="mis-obras"></span>
          Mis obras
        </a>
        <a href="/fundacion">
          <span role="img" aria-label="fundacion"></span>
          Fundación
        </a>
        <a href="/prensa">
          <span role="img" aria-label="prensa"></span>
          Prensa
        </a>
        <a href="/videoteca">
          <span role="img" aria-label="videoteca"></span>
          Videoteca
        </a>
        {/* <a href="/redes-sociales">
          <span role="img" aria-label="redes-sociales"></span>
          Redes Sociales
        </a> */}
        {/* <a href="/contacto">
          <span role="img" aria-label="contacto"></span>
          Contacto
        </a> */}
      </StyledMenu>
    </div>
  )
}

Menu.propTypes = {
  open: bool.isRequired,
}

export default Menu;
