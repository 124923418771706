import React from 'react';
import { StyledVideoBackground } from './VideoBackground.styled';
import SocialNetwork from '../../components/SocialNetwork/SocialNetwork';
import video from '../../video/propuesta_ok.mp4';
import poster from '../../video/video_1280x720.gif';

const VideoBackground = () => {
  return (
    <StyledVideoBackground className="background">
      <div className="video-background">
        <div className="video-foreground"  >
          <video loop="true" autoplay="autoplay" muted="muted" poster={poster}>
            <source src={video} type="video/mp4" />
          </video>
        </div>
      </div>
      <SocialNetwork />
    </StyledVideoBackground>
  );
}

export default VideoBackground;