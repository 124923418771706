import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from '../../global/Global';
import { theme } from '../../theme/theme';
import Cabecera from '../../components/Cabecera/Cabecera';
import Burger from '../../components/Burger/Burger';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import React, { useState, useRef } from 'react';
import { useOnClickOutside } from '../../hooks/hooks';
import CarruselBio from "../../components/CarruselBio/CarruselBio";
import CarruselFundacion from "./CarruselFundacion";

// Iconos
import { Helmet } from "react-helmet";

const Fundacion = () => {
  const [open, setOpen] = useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setOpen(false));
  const imagesBio = [
    "img-01.jpg",
    "img-02.jpg",
    "img-03.jpg",
    "img-04.jpg",
    "img-05.jpg",
    "img-06.jpg",
    "img-07.jpg",
    "img-09.jpg",
    "img-10.jpg",
    "img-11.jpg",
    "img-12.jpg",
    "img-13.jpg",
    "img-14.jpg",
    "img-15.jpg",
    "img-16.jpg",
    "img-17.jpg",
    "img-18.jpg",
    "img-19.jpg",
    "img-20.jpg",
    "img-21.jpg",
    "img-22.jpg",
    "img-23.jpg",
    "img-24.jpg",
    "img-25.jpg",
    "img-26.jpg",
    "img-27.jpg",
    "img-28.jpg",
    "img-29.jpg",
    "img-30.jpg"]
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>César Acuña Peralta - Fundación  </title>
        <meta name="description"
          content="Como muchos peruanos, César Acuña estaba más cerca de un destino de pobreza, de informalidad, de desigualdad, que de éxito. Sin embargo, se sobrepuso a ello. Y se convirtió en un empresario exitoso, fundó un partido político, fue alcalde, fue gobernador, y ocupó puestos de relevancia en política." />
        <meta property="og:title" content="César Acuña Peralta  - Fundación  " />
        <meta property="og:image" content="%PUBLIC_URL%/cap-foto.png" />
        <meta property="og:url" content="https://cesaracuna.pe" />
      </Helmet>
      <GlobalStyles />
      <section id='fundacion'>
        <Cabecera titulo={"Fundación"} />
        <div className='body-page'>
          <div className='container'>
            <div className="col-lg-10 mx-auto p-5 text-start">
              <h2 className="subtitle " style={{ margin: '0 0 30px' }}>La Fundación César Acuña Peralta</h2>
              <div className="container p-0">
                <div className="row">
                  <div className="col-sm-12 col-md-12 pt-4">
                    <p style={{ color: '#A8A8A8' }}>
                      La Fundación César Acuña Peralta (FCAP), es una organización no gubernamental de desarrollo en el
                      ámbito nacional, sin fines de lucro; constituida el 23 de diciembre de 2016, con Registro de
                      Administración Nacional de Fundaciones Nº 347, y con Resolución en el Consejo de Supervigilancia de Fundaciones
                      Nº 115-2017/JUS/CSF.
                      <br />
                      <br />
                      La Fundación comprometida con la comunidad y el marco de la realidad mundial post pandemia; basa
                      sus acciones en el desarrollo integral y sostenible de la persona, principalmente en los
                      territorios donde se asientan las familias de extrema pobreza, presentando brechas sociales con indicadores
                      altos, mostrando el escaso acceso a educación, salud y bienestar.
                      <br />
                    </p>
                  </div>
                  {/* <div className="col-sm-12 col-md-6 align-self-center">
                    <iframe width="100%" height="305" src="https://www.youtube.com/embed/YuGLNeKIQZE"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>
                  </div> */}
                </div>
              </div>
              <p style={{ color: '#A8A8A8' }}>
                <br />
                Uno de los desafíos de la fundación esta orientada a la ejecución de proyectos innovadores y
                emprendedores, con oportunidades en igualdad de condiciones para varones y mujeres, donde las familias
                menos favorecidas puedan tener acceder a generar una renta para sus hogares y que se contribuya al
                bienestar de estas, con un nuevo enfoque territorial, basado en los Objetivos de Desarrollo Sostenible.
                <br /><br />
                Las tendencias mundiales hacen que en el marco de la reactivación económica, se establezcan trabajos
                cooperativos en bien de las comunidades mas necesitadas; en tal sentido, la fundación viene impulsando
                la educación comunitaria, trabajando por varios años con el programa de Pronoeis, atendiendo a niños y
                niñas de 3, 4 y 5 años de edad en educación inicial, haciendo que aquellas población donde no cuentan
                con una institución educativa del nivel inicial, tengan la oportunidad que le brinda la fundación. Este
                programa se realiza con alianzas estratégicas con la comunidad, estableciendo como prioridad que los
                niños y niñas tenga una educación de calidad.
                <br /><br />
                Las FCAP; cuenta con profesionales voluntarios de alto nivel dispuestos a trabajar ejerciendo ética,
                liderazgo y compromiso, que conlleve a aportar al desarrollo sostenible de las comunidades.
                <br /><br />
                Los principales sectores a los cuales la fundación orienta sus actividades son:
                <br />
                <ol>
                  <li>EDUCACION</li>
                  <li>SALUD</li>
                  <li>CULTURA</li>
                  <li>DEPORTE</li>
                  <li>INFRAESTRUCTURA</li>
                  <li>SOCIAL</li>
                  <li>PROYECTOS ESPECIALES.</li>
                </ol>
                <br />
                La Fundación César Acuña Peralta (FCAP), tiene como marco mundial, dar cumplimiento a los Objetivos de
                Desarrollo Sostenible (Agenda 2030); donde principalmente trabaja con un enfoque basado en la persona,
                orientando sus líneas de acción para que esta mejore su calidad de vida. Las brechas sociales, cuyos
                indicadores se han acentuado ante la presencia de la pandemia, principalmente en las zonas de extrema
                pobreza, se han evidenciado carencias donde muchas familias no cuentan con acceso a servicios básicos y
                principalmente han sido fuertemente golpeados económicamente, por los confinamientos y donde no contaban
                con ningún ingreso económico. Ante este panorama, se hace necesario orientar nuestros esfuerzos hacia
                sectores donde sus necesidades de acceso a educación, salud, generación de empleo, son prioridad.
              </p>
            </div>
          </div>
          <div className='container-fluid p-0' style={{ backgroundColor: '#E5F2F6' }}>
            <div className="col-lg-10 mx-auto p-5 text-center">
              {/* <iframe width="100%" style={{ maxWidth: '480px' }} height="315"
                src="https://www.youtube.com/embed/YuGLNeKIQZE"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe> */}
            </div>
          </div>
          <div className='container'>
            <div className="col-lg-10 mx-auto text-center">
              <p>
                La Fundación considera que seguirá trabajando en las siguientes líneas de acción dirigidas a:
              </p>
              <CarruselFundacion></CarruselFundacion>
            </div>
          </div>
        </div>
      </section>
      <div className='container-fluid' style={{ background: "#2B2C82" }}>
        <div className="container">
          <div className='row py-5'>
            <CarruselBio carruselIMG={imagesBio} />
          </div>
        </div>
      </div>
      <Footer />
      <div ref={node}>
        <Burger open={open} setOpen={setOpen} />
        <Menu open={open} setOpen={setOpen} />
      </div>
    </ThemeProvider>
  );
}

export default Fundacion;