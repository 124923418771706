// Obras.styled.js
import styled from 'styled-components';
export const StyledObras = styled.div`
    width: 320px;
    margin: 20px auto 30px;
    display: flex;
    flex-direction: column;

    @media (min-width: ${({ theme }) => theme.mobile}) {
        width: 100%;
        max-width: 1200px;
        margin: 40px auto 30px;
    }

    .work-row {
        width: 100%;
        height: auto;
        position: relative;
        margin: 0;
        padding: 10px 0 20px;
        overflow: hidden;

        &:before {
            width: 2px;
            height: 100%;
            position: absolute;
            background: #464AFF;
            content: "";
            left: 50%;
            transform: translateX(-50%);
        }

        @media (min-width: ${({ theme }) => theme.mobile}) {
            width: 100%;
            max-width: 1200px;
            padding: 0 0 20px;
            overflow: inherit;

            &.right {
                .year {
                    right: -54%;
                    left: inherit;
                }
            }
    
            &.left {
                .year {
                    left: 34%;
                }
            }
        }

        .year {
            position: relative;
            left: 30%;
            text-align: left;
            font: 18px GothamLight;
            letter-spacing: 0px;
            color: rgb(70, 74, 255);
            top: 25px;

            @media (min-width: ${({ theme }) => theme.mobile}) {
                font: 66px GothamLight;
            }
        }

        .separation {
            width: 30px;
            height: 30px;
            position: relative;
            background: rgb(255, 255, 255);
            border: 2px solid rgb(70, 74, 255);
            border-radius: 50%;
            content: "";
            left: 50%;
            transform: translateX(-50%);
            z-index: 0;
            margin: 0 0 10px;

            @media (min-width: ${({ theme }) => theme.mobile}) {
                top: -26px;
            }

            &:before {
                width: 20px;
                height: 20px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 50%;
                background: #464AFF;
                content: "";
            }
        }

        .box-content {
            background: #FFF;
            position: relative;
            z-index: 1;

            @media (min-width: ${({ theme }) => theme.mobile}) {
                width: 630px;
                background: transparent;
            }

            .image-box {
                margin: 0 0 10px;

                @media (min-width: ${({ theme }) => theme.mobile}) {
                    width: 480px;
                    height: 270px;
                    border-radius: 30px;
                    position: relative;
                    overflow: hidden;
                    
                    img {
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            .text-box {
                padding: 5px 10px;

                @media (min-width: ${({ theme }) => theme.mobile}) {
                    width: 450px;
                    margin-top: -50px;
                    padding: 45px 28px;
                    background: #FFF;
                    border-radius: 30px;
                    position: relative;
                }
                
                h3 {
                    overflow-wrap: break-word;
                }
            }
        }
    }
`;