import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from '../../global/Global';
import { theme } from '../../theme/theme';
import Cabecera from '../../components/Cabecera/Cabecera';
import Burger from '../../components/Burger/Burger';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import { useState, useRef } from 'react';
import { useOnClickOutside } from '../../hooks/hooks';
import { Helmet } from "react-helmet";
import Works from '../../components/Obras/Obras';

const MisObras = () => {
    const [open, setOpen] = useState(false);
    const node = useRef();
    useOnClickOutside(node, () => setOpen(false));

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <Helmet>
                <title>César Acuña Peralta - Mis Obras</title>
                <meta name="description"
                    content="César Acuña Peralta, alcalde actual, ha realizado una destacada labor en la gestión de obras públicas. Se ha enfocado en proyectos de infraestructura básica, como la construcción de parques, pistas y veredas, contribuyendo así al mejoramiento urbano y a la calidad de vida de los ciudadanos. Su enfoque en el desarrollo de estas obras ha sido notable y ha generado un impacto positivo en la comunidad local. Estas acciones demuestran su compromiso con el progreso y el bienestar de su municipio como Gobernador regional de la Libertad." />
                <meta property="og:title" content="César Acuña Peralta  - Mis Obras" />
                <meta property="og:image" content="%PUBLIC_URL%/cap-foto.png" />
                <meta property="og:url" content="https://cesaracuna.pe/mis-obras" />
            </Helmet>
            <section id='mis-obras'>
                <Cabecera titulo={"Mis Obras"} />
                <div className='body-page'>
                    <Works className='container-fluid' />
                </div>
            </section>
            <Footer />
            <div ref={node}>
                <Burger open={open} setOpen={setOpen} />
                <Menu open={open} setOpen={setOpen} />
            </div>
        </ThemeProvider>
    );
}

export default MisObras;



