// Footer.styled.js
import styled from 'styled-components';
export const StyledFooter = styled.footer`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  text-align: center;
  background: ${({ theme }) => theme.gris} 0% 0% no-repeat padding-box;
  color: ${({ theme }) => theme.white};

  @media (min-width: ${({ theme }) => theme.mobile}) {
    height: 200px;
  }

  .container {
    max-width: 1200px;
    height: auto;
    display: flex;
    margin: 20px 0;
    justify-content: center;
    text-align: center;
    flex-direction: column;

    p.text {
      letter-spacing: 0px;
      color: #E0E0E0;
    }

    nav.box-social-network {
      width: 100%;
      display: flex;

      ul {
        margin: 0 auto;
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: row;

        li {
          width: 44px;
          height: 44px;
          display: flex;
          justify-content: center;
          text-align: center;
          border-radius: 50%;
          background: #E0E0E0;
          color: ${({ theme }) => theme.gris};
          margin: 0 5px;
          line-height: 44px;
          text-align: center;
          overflow: hidden;

          a {
            width: 100%;
            color: ${({ theme }) => theme.gris};
            text-decoration: none;
            transition: all .3s ease-in-out;

            &:hover {
              color: #E0E0E0;
              background: ${({ theme }) => theme.gris};
            }
          }
        }
      }
    }
  }
`;