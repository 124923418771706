import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from '../../global/Global';
import { theme } from '../../theme/theme';
import Cabecera from '../../components/Cabecera/Cabecera';
import Burger from '../../components/Burger/Burger';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import { useState, useRef } from 'react';
import { useOnClickOutside } from '../../hooks/hooks';
import { Helmet } from "react-helmet";
import './NotFoundPage.css'

const NotFoundPage = () => {
    const [open, setOpen] = useState(false);
    const node = useRef();
    useOnClickOutside(node, () => setOpen(false));

    return (
        <ThemeProvider theme={theme}>
            <Helmet>
                <title>César Acuña Peralta - 404 - Página no encontrada  </title>
                <meta name="description"
                    content="Lo sentimos, la página que estás buscando no existe." />
                <meta property="og:title" content="César Acuña Peralta  - 404 - Página no encontrada  " />
                <meta property="og:image" content="%PUBLIC_URL%/cap-foto.png" />
                <meta property="og:url" content="https://cesaracuna.pe" />
            </Helmet>
            <GlobalStyles />
            <section id='mis-obras'>
                <Cabecera titulo={"404 - Página no encontrada"} />
                <div className='body-page'>
                    <div id="notfound">
                        <div class="notfound">
                            <div class="notfound-code">
                                <h1>404</h1>
                            </div>
                            <h2>Lo sentimos, la página que estabas buscando no se ha encontrado o no existe.</h2>
                            <p>La página que estabas viendo, es posible que haya sido removida, se haya cambiado la ruta de acceso o esté temporalmente deshabilitada</p>
                            <a href="/">Regresar al Inicio</a>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <div ref={node}>
                <Burger open={open} setOpen={setOpen} />
                <Menu open={open} setOpen={setOpen} />
            </div>
        </ThemeProvider>
    );
};

export default NotFoundPage;
