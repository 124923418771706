import React, { useState } from "react";
import Slider from "react-slick";
import './CarruselBio.css'
import PropTypes from "prop-types";
import ModalZoom from "../ModalZoom/ModalZoom";

const noteimg = require.context('../../images/gallery')


const CarruselBio = ({ carruselIMG }) => {

  const [openModal, setOpenModal] = useState(false);
  const [image, setimage] = useState('img-01.jpg');
  var settings = {
    centerMode: true,
    centerPadding: '100px',
    slidesToShow: 1,
    variableWidth: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          centerMode: true,
          centerPadding: '40px',
          variableWidth: false,
          slidesToShow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          centerMode: false,
          variableWidth: false,
          slidesToShow: 1
        }
      }
    ]
  };
  console.log(carruselIMG)

  function abrirmodal(value) {
    setOpenModal(!openModal);
    setimage(value)
  }

  return (
    <>
      <Slider {...settings}>
        {carruselIMG.map((value) => <div className="px-2">
          <div className="md:rounded-2 md:shadow bg-body-tertiary md:rounded overflow-hidden">
            <img loading="lazy" className="img-carr " src={noteimg('./' + value)} alt="note" onClick={() => abrirmodal(value)} />
          </div>
        </div>)}
      </Slider>
      <ModalZoom setOpenModal={setOpenModal} image={image} openModal={openModal}></ModalZoom>
    </>
  );
}

CarruselBio.propTypes = {
  carruselIMG: PropTypes.array,
}
export default CarruselBio