import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from '../../global/Global';
import { theme } from '../../theme/theme';
import Cabecera from '../../components/Cabecera/Cabecera';
import Burger from '../../components/Burger/Burger';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import React, { useState, useRef } from 'react';
import { useOnClickOutside } from '../../hooks/hooks';
import capFoto from '../../images/cap-foto.png';
import imageBio from '../../images/gallery/img-02.jpg';
import VerMas from '../../components/VerMas/VerMas';
import CarruselBio from "../../components/CarruselBio/CarruselBio";
import './Biografia.css'
import { Helmet } from "react-helmet";

const Biografia = () => {
  const imagesBio = [
    "img-01.jpg",
    "img-02.jpg",
    "img-03.jpg",
    "img-04.jpg",
    "img-05.jpg",
    "img-06.jpg",
    "img-07.jpg",
    "img-09.jpg",
    "img-10.jpg",
    "img-11.jpg",
    "img-12.jpg",
    "img-13.jpg",
    "img-14.jpg",
    "img-15.jpg",
    "img-16.jpg",
    "img-17.jpg",
    "img-18.jpg",
    "img-19.jpg",
    "img-20.jpg",
    "img-21.jpg",
    "img-22.jpg",
    "img-23.jpg",
    "img-24.jpg",
    "img-25.jpg",
    "img-26.jpg",
    "img-27.jpg",
    "img-28.jpg",
    "img-29.jpg",
    "img-30.jpg"]
  const [open, setOpen] = useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setOpen(false));
  const text1 = `<p>Durante los últimos años, César Acuña logró consolidar la Universidad César Vallejo como una de las mejores instituciones educativas del Perú. Su liderazgo permitió ampliar la oferta académica, fortalecer la internacionalización y mejorar la infraestructura, alcanzando importantes reconocimientos.
                            Gracias a su visión estratégica y compromiso con la educación de calidad, la universidad logró posicionarse como referente en la formación de profesionales altamente capacitados y en la generación de investigaciones innovadoras. Los logros obtenidos durante este periodo fueron clave para impulsar el crecimiento y desarrollo de la universidad, generando más oportunidades y consolidando el legado de César Acuña en la educación superior.</p>
                            
                            <div className="row">
                                <div className="col-md-6 offset-md-3">
                                    <ul class="timeline text-start">
                                        <li>
                                            <h5>2023</h5>
                                            <p>Universidad César Vallejo se convierte en una de las tres mejores universidades privadas del país</p><p></p>
                                        </li>
                                        <li>
                                            <h5>2007</h5>
                                            <p>Fundador de la Universidad Autónoma del Perú, Lima</p>
                                        </li>
                                        <li>
                                            <h5>&nbsp;</h5>
                                            <p>Promotor de cinco canales de televisión en diferentes regiones del Perú</p>
                                        </li>
                                        <li>
                                            <h5>2004</h5>
                                            <p>Fundador del Colegio Harvard Collage, Piura</p>
                                        </li>
                                        <li>
                                            <h5>2001</h5>
                                            <p>Fundador de Filiales de la Universidad César Vallejo en Piura, Chiclayo, Chimbote, Tarapoto, Moyobamba, Huaraz y Lima (Lima Norte, Ate, San Juan de Luriganch, Callao)</p>
                                        </li>
                                        <li>
                                            <h5>1999</h5>
                                            <p>Fundador de la Universidad Señor de Sipán, Chiclayo</p>
                                        </li>
                                        <li>
                                            <h5>1991</h5>
                                            <p>Fundador del Colegio Ingeniería, Trujillo<br>Fundador de la Universidad César Vallejo, Trujillo</p>
                                        </li>
                                        <li>
                                            <h5>1980</h5>
                                            <p>Fundador de la Academía Ingeniería, Trujillo</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            `;
  const text2 = ` <p>
                Realizó una destacada función fiscalizadora. César Acuña fue el congresista que en forma valiente
                presentó la primera acusación constitucional contra el ex Presidente de la República, Alberto Fujimori
                Fujimori, por infringir la Constitución, la Ley y el Reglamento. Integró la Sub Comisión Investigadora
                sobre el vídeo de la corrupción, caso Kouri - Montesinos, la Sub Comisión Investigadora sobre el vídeo
                de la indignación, caso Gamarra y la Sub Comisión Investigadora sobre la Contraloría General de la
                República a cargo de Carmen Higaona.<br/><br/>
                Presidió la Comisión de Cultura y Patrimonio Cultural del Congreso de la República, en la legislatura
                ordinaria 2001-2002 en integró las Comisiones de Educación, Constitución y Descentralización.<br/><br/>
                El 08 de diciembre de 2001 César Acuña Peralta cumple otro de sus sueños junto a un grupo de sus más
                cercanos colaboradores, funda en la ciudad de Trujillo el nuevo partido político nacional, Alianza para
                el Progreso (APP). <br/><br/>
                César Acuña ha sido un candidato destacado en la política peruana en los últimos años, con dos
                candidaturas en importantes elecciones. En las dos elecciones presidenciales, en el 2016 y 2021, su
                campaña se enfocó en presentar una visión de cambio y progreso para el país, proponiendo soluciones para
                los principales problemas que enfrenta la sociedad peruana.<br/><br/>
                Aunque no logró ganar la presidencia en ninguna de las dos elecciones, su participación fue destacada y
                logró obtener importantes porcentajes de votos para el partido Alianza Para el Progreso en ambas
                contiendas dándole una representación importante en el congreso de la república. <br/><br/>
                Actualmente, el partido político APP tiene una bancada en el congreso y autoridades a nivel nacional en
                todos los niveles de gobierno. En el Congreso, APP ha presentado diversas iniciativas legislativas y ha
                participado en debates de interés nacional.<br/><br/>
                A nivel regional, destaca la labor del presidente fundador del partido, César Acuña Peralta, quien
                actualmente desempeña el cargo de Gobernador Regional de La Libertad tras ser electo en las últimas
                Elecciones Municipales y Regionales 2022. Bajo su liderazgo, la región se ha reactivado y se está
                avanzando en importantes proyectos de desarrollo económico y social, como la construcción de hospitales
                y escuelas, así como en la implementación de políticas públicas para mejorar la calidad de vida de los
                ciudadanos.<br/><br/>
                Alianza Para El Progreso se mantiene comprometido en trabajar por el bienestar del país y sus
                ciudadanos, promoviendo el diálogo y la colaboración entre las distintas fuerzas políticas y
                sociales.<br/><br/>
                César Acuña se ha consolidado como una figura política influyente en el país, manteniendo su compromiso
                de trabajar por el bienestar de la sociedad peruana desde los distintos espacios de representación
                política.<br/>
              </p>

              <h2 className='subtitle text-center mb-3' style="color: #464AFF;">Cargos políticos asumidos por César Acuña</h2>
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <ul class="timeline text-start">
                         <li>
                            <h5>2023 - 2026</h5>
                            <p>Gobernador regional de la Libertad</p>
                        </li>
                        <li>
                            <h5>2015 - 2016</h5>
                            <p>Presidente Regional de La Libertad</p>
                        </li>
                        <li>
                          <h5>2015</h5>
                          <p>Presidente de la asociación de Gobiernos del Perú - ANGR</p>
                        </li>
                        <li>
                          <h5>2011 - 2014</h5>
                          <p>Alcalde Reelecto de la Municipalidad Provincial de Trujillo</p>
                        </li>
                        <li>
                          <h5>2007 - 2010</h5>
                          <p>Presidente de la Asociación de Municipalidades del Perú - AMPE</p>
                        </li>
                        <li>
                          <h5>2007 - 2010</h5>
                          <p>Alcalde de la Municipalidad Provincial de Trujillo<br/>(Terminó con la hegemonía que durante 40
                            años mantuvo el APRA en el gobierno local)</p>
                        </li>
                        <li>
                          <h5>2001 - 2006</h5>
                          <p>Congresista reelecto de la República del Perú para el periodo</p>
                        </li>
                        <li>
                          <h5>2001</h5>
                          <p>Fundador del partido político Alianza Para el Progreso</p>
                        </li>
                        <li>
                          <h5>2000</h5>
                          <p>Congresista electo de la República del Perú para el periodo</p>
                        </li>
                      </ul>
                  </div>
                </div>
          
                            `;
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>César Acuña Peralta -  Biografía  </title>
        <meta name="description" content="Como muchos peruanos, César Acuña estaba más cerca de un destino de pobreza, de informalidad, de desigualdad, que de éxito. Sin embargo, se sobrepuso a ello. Y se convirtió en un empresario exitoso, fundó un partido político, fue alcalde, fue gobernador, y ocupó puestos de relevancia en política." />
        <meta property="og:title" content="César Acuña Peralta - Biografía " />
        <meta property="og:image" content="%PUBLIC_URL%/cap-foto.png" />
        <meta property="og:url" content="https://cesaracuna.pe" />
      </Helmet>
      <GlobalStyles />
      <GlobalStyles />
      <section id='biografia'>
        <Cabecera titulo={"Biografía"}></Cabecera>
        <div className='container-fluid body-page'>
          <div className='row'>
            <div className='col-sm-12 col-md-5 mobile-cap-foto'>
              <div className='cap-foto'>
                <img src={capFoto} title="César Acuña Peralta" alt="César Acuña Peralta" loading="lazy" />
              </div>
            </div>
            <div className='col-sm-12 col-md-5 text-start pt-5 d-flex flex-column align-self-center'>
              <h2 className='subtitle'>César Acuña Peralta</h2>
              <p>
                La biografía de César Acuña comienza el 11 de agosto de 1952 en el distrito de Tacabamba, provincia de
                Chota, departamento de Cajamarca. Es el tercero de doce hijos. Sus padres fueron Don Héctor Acuña
                Cabrera y Doña Clementina Peralta Alvarado, personas dedicadas a las labores agrícolas.
                <br /><br />
                Como muchos peruanos, César Acuña estaba más cerca de un destino de pobreza, de informalidad, de
                desigualdad, que de éxito. Sin embargo, se sobrepuso a ello. Y se convirtió en un empresario exitoso,
                fundó un partido político, fue alcalde, fue gobernador, y ocupó puestos de relevancia en política.
              </p>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='col-lg-10 mx-auto py-3 py-md-5'>
            <h2 className='subtitle text-start mb-3'>Trayectoría como empresario</h2>
            <p className='text-start'>
              Como empresario, César Acuña Peralta, viene realizando una exitosa labor. Fundó el Consorcio Universitario
              más grande del país integrado por las Universidad Cesar Vallejo, Universidad Señor de Sipán y Universidad
              Autónoma del Perú.<br /><br />
              La <b>Universidad César Vallejo</b> tiene sedes en Trujillo, Piura, Tarapoto, Chiclayo, Chimbote, Lima y
              Callao. La Universidad Señor de Sipán se encuentra en Chiclayo y la Universidad Autónoma del Perú en el
              distrito de Villa el Salvador, Lima. También ha fundado el colegio Ingeniería y el Harvard College en
              Piura.
              La fundación de la Universidad César Vallejo es uno de los momentos más importantes de la biografía de
              César Acuña y muestra firme de su carácter emprendedor y éxito como empresario. A pesar de que César Acuña
              crea la Academia Preuniversitaria de Ingeniería y prepara a escolares para que ingresen a la universidad,
              existía mucha frustración entre la gente. Miles de postulantes de varias regiones del país se quedaban sin
              ingresar a la Universidad Nacional de Trujillo.<br /><br />
            </p>
            <VerMas contenidoHtml={text1}></VerMas>
          </div>
          <div className='col-lg-10 mx-auto py-3 py-md-5'>
            <div className='py-3 text-start'>
              <h2 className='subtitle text-start mb-3'>Trayectoria de César Acuña político</h2>
              <p>
                César Acuña Peralta es un político que conoce la problemática peruana desde sus cimientos.<br /><br />
                Su fortaleza y su espíritu democrático lo llevaron a ser invitado en 1980 a postular como diputado por
                la Izquierda Unida de Alfonso Barrantes Lingán. Desde aquella época tiene mucha vocación e inclinación
                por la política de servir a la comunidad, que es una expresión de formación desde su niñez. El humanismo
                que practica lo viene demostrando en toda su biografía, en su colegio, en la universidad, en la
                academia, en sus universidades y ahora en la política.<br /><br />
                César Acuña Peralta fue Congresista electo por Unidad Nacional para el período 2000 - 2005 y reelecto
                para el 2001 - 2006. Trabajó de forma intensa a favor del departamento de La Libertad y su capital
                Trujillo, ciudad donde realizó innumerables obras de corte social, apoyando permanentemente a los
                ciudadanos de menos recursos económicos, quienes le retribuyeron con su voto al elegirlo por amplio
                margen Alcalde de Trujillo el año 2006.<br /><br />
              </p>
              <VerMas contenidoHtml={text2}></VerMas>
            </div>
          </div>
        </div>
        <div className='container-fluid frase-white text-center p-3'>
          <h3>“Tengo una deuda con La Libertad,mi compromiso es trabajar <br /> sin descanso estos cuatro años por todos
            los liberteños”</h3>
        </div>
        <div className='container'>
          <div className='col-lg-10 mx-auto p-3 py-md-5'>
            <div className='row py-5'>
              <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center'>
                <div>
                  <h3 className='subtitle mb-3'>Empatía con los peruanos</h3>
                  <p className='text-start'>Sus orígenes humildes le han permitido sentir los problemas de los más
                    desfavorecidos. Por eso, cuando alcanzó la estabilidad creó la Fundación César Acuña, que hoy
                    ayuda a muchos peruanos.</p>
                  <h3 className='subtitle mb-3'>Ayuda social otorgada por César Acuña</h3>
                  <div className='row cuadroBio'>
                    <div className='col-sm-12 col-md-6'>
                      <div className='d-flex mb-2'>
                        <div className='align-items-center text-start pe-1'>
                          <i className="fa-solid fa-check" style={{ color: '#DC0042' }}></i>
                        </div>
                        <div className='align-items-center text-start'>
                          <span>Desde que me inicié en la academia ingeniería otorgué becas de estudio a muchos jóvenes que no tenían recursos económicos pero si, las ganas de estudiar.</span>
                        </div>
                      </div>
                      <div className='d-flex mb-2'>
                        <div className='align-items-center text-start pe-1'>
                          <i className="fa-solid fa-check" style={{ color: '#DC0042' }}></i>
                        </div>
                        <div className='align-items-center text-start'>
                          <span>Como político, desde que asumí la alcaldía de Trujillo no cobre el sueldo que me correspondía  y ese dinero se destinó para el Programa Jardines Municipales que daba educación gratuita a niños pobres y para el Programa “Hogar Vida Nueva” que daba desayuno con leche fresca a 1600 niños de los distritos de la provincia de Trujillo.</span>
                        </div>
                      </div>
                      <div className='d-flex mb-2'>
                        <div className='align-items-center text-start pe-1'>
                          <i className="fa-solid fa-check" style={{ color: '#DC0042' }}></i>
                        </div>
                        <div className='align-items-center text-start'>
                          <span>Puse en marcha el programa “Agua es Vida” que distribuye agua de manera gratuita a más de 90 mil familias de los lugares más excluidos de Trujillo desde el 2005.</span>
                        </div>
                      </div>
                      <div className='d-flex mb-2'>
                        <div className='align-items-center text-start pe-1'>
                          <i className="fa-solid fa-check" style={{ color: '#DC0042' }}></i>
                        </div>
                        <div className='align-items-center text-start'>
                          <span>Hoy en día la Fundación César Acuña trabaja incansablemente para ayudar a personas necesitadas del Perú. Desde las escuelas de educación inicial, ayuda en temas de salud, hasta la entrega de alimentos y medicinas, la fundación ha transformado miles de vidas. Gracias a su compromiso social, César Acuña y su equipo continúan siendo un modelo a seguir en la ayuda y el bienestar de la comunidad.</span>
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-6'>
                      <div className='d-flex mb-2'>
                        <div className='align-items-center text-start pe-1'>
                          <i className="fa-solid fa-check" style={{ color: '#DC0042' }}></i>
                        </div>
                        <div className='align-items-center text-start'>
                          <span>En homenaje a mi madre creé la Fundación “Clementina Peralta de Acuña” que brindó educación inicial gratuita y alimentación a miles de niños y niñas en desamparo económico.</span>
                        </div>
                      </div>
                      <div className='d-flex mb-2'>
                        <div className='align-items-center text-start pe-1'>
                          <i className="fa-solid fa-check" style={{ color: '#DC0042' }}></i>
                        </div>
                        <div className='align-items-center text-start'>
                          <span>Cree el PUMAS (Programa Urbano Marginal de Atención en Salud) que tiene cuatro centros de salud de atención gratuita en Florencia de Mora, El Porvenir, La Esperanza y Víctor Larco.</span>
                        </div>
                      </div>
                      <div className='d-flex mb-2'>
                        <div className='align-items-center text-start pe-1'>
                          <i className="fa-solid fa-check" style={{ color: '#DC0042' }}></i>
                        </div>
                        <div className='align-items-center text-start'>
                          <span>Impulsó a más de 300 comedores populares para ser autogestionados.</span>
                        </div>
                      </div>
                      <div className='d-flex mb-2'>
                        <div className='align-items-center text-start pe-1'>
                          <i className="fa-solid fa-check" style={{ color: '#DC0042' }}></i>
                        </div>
                        <div className='align-items-center text-start'>
                          <span>Como empresario, beneficios especiales para que estudien una profesión en la Universidad César Vallejo a los alumnos premios de excelencia, los alcaldes escolares, los maestros y sus hijos, los policías y sus hijos, los empleados municipales y sus hijos, los canillitas y sus hijos, los hijos de las socias de los clubes de madres.</span>
                        </div>
                      </div>
                      <div className='d-flex mb-2'>
                        <div className='align-items-center text-start pe-1'>
                          <i className="fa-solid fa-check" style={{ color: '#DC0042' }}></i>
                        </div>
                        <div className='align-items-center text-start'>
                          <span>Para los maestros creó un programa quinquenal de capacitación gratuita. Hasta el momento han sido beneficiados  más de 50 mil.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-sm-12 col-md-5'>
                <img src={imageBio} style={{ borderRadius: '2rem', width: '20rem' }}
                  alt="Ayuda social otorgada por César Acuña" title="Ayuda social otorgada por César Acuña"
                  loading="lazy" />
                <img src={imageBio} style={{ borderRadius: '2rem', width: '20rem' }}
                  alt="Ayuda social otorgada por César Acuña" title="Ayuda social otorgada por César Acuña"
                  loading="lazy" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='container-fluid' style={{ background: "#2B2C82" }}>
        <div className="container">
          <div className='row py-5'>
            <CarruselBio carruselIMG={imagesBio} />
          </div>
        </div>
      </div>
      <Footer />
      <div ref={node}>
        <Burger open={open} setOpen={setOpen} />
        <Menu open={open} setOpen={setOpen} />
      </div>
    </ThemeProvider>
  );
}

export default Biografia;