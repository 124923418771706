import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from '../../global/Global';
import { theme } from '../../theme/theme';
import Cabecera from '../../components/Cabecera/Cabecera';
import Burger from '../../components/Burger/Burger';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import React, { useState, useRef } from 'react';
import { useOnClickOutside } from '../../hooks/hooks';
import CarruselBlog from './CarruselBlog'
import './Prensa.css'
import json from '../MenuPrensa/notas.json'
import { Helmet } from "react-helmet";

const noteimg = require.context('../../images/blog')

const Prensa = () => {
  const [open, setOpen] = useState(false);
  const node = useRef();
  const slug = window.location.pathname.split('/')[2]

  const found = (json, slug) => {
    return json.find(element => element.slug === slug) ?? -1;
  }
  const note = found(json, slug)

  const fecha = 'Publicado el ' + note.date
  const titulo = note.title
  const parrafo = note.paragraf
  const url = window.location.href
  const urlparse = window.location.href.split('/').join('%2F').split(':').join('%3A')
  useOnClickOutside(node, () => setOpen(false));

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>César Acuña Peralta - {titulo} </title>
        <meta name="description"
          content={parrafo} />
        <meta property="og:title" content={titulo} />
        <meta property="og:image" content={noteimg('./' + note.img)} />
        <meta property="og:url" content={url} />
      </Helmet>
      <GlobalStyles />
      <section id="prensa">
        <Cabecera titulo={"Prensa"} subtitulo={"Blog"} link="/prensa" />
        <div className='body-page'>
          <div className='container'>
            <div className='row'>
              <div className="container">
                <div className='container  '>
                  <div className='col-sm-12 col-md-12'>
                    <h3 className='text-start fecha'>{fecha}</h3>
                    <h2 className='text-start titulo'>{titulo}</h2>
                    <img className="card-images float-end ms-3" src={noteimg('./' + note.img)} alt={note.title} loading="lazy" />
                    <p className='text-start parrafo' dangerouslySetInnerHTML={{ __html: parrafo }}></p>
                    <a className="fb-share-button" target="_blank" href={`http://www.facebook.com/sharer.php?u=` + url}>
                      <i className="fa-brands fa-facebook"></i>Compartir</a>
                    <a className="tw-share-button"
                      href={`https://twitter.com/intent/tweet?text=Mira%20nuestro%20blog&url=` + url + `&via=CesarAcunaP&hashtags=APP,CesarAcunaP`}
                      target="_blank"><i className="fa-brands fa-twitter"></i>Twittear</a>
                    <a className="wh-share-button"
                      href={`https://api.whatsapp.com/send?text=Visita%20el%20blog%20de%20` + url}
                      target="_blank"><i className="fa-brands fa-whatsapp"></i>Whatsapp</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='container-fluid' style={{ background: "#2B2C82" }}>
            <div className="container">
              <div className='row pb-5'>
                <div className='col-sm-12 col-md-6 pt-5 mb-2'>
                  <h3 className='text-white text-start'>Notas relacionadas:</h3>
                </div>
                <CarruselBlog></CarruselBlog>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <div ref={node}>
        <Burger open={open} setOpen={setOpen} />
        <Menu open={open} setOpen={setOpen} />
      </div>
      <div id="fb-root"></div>
    </ThemeProvider >
  )
    ;
}

export default Prensa;