import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from '../../global/Global';
import { theme } from '../../theme/theme';
import Cabecera from '../../components/Cabecera/Cabecera';
import Burger from '../../components/Burger/Burger';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import React, { useState, useRef, useEffect } from 'react';

import Pagination from '../../components/Pagination/Pagination'
import Videos from '../../components/Videos/Videos';

import { useOnClickOutside } from '../../hooks/hooks';
import './Videoteca.css'
import { Helmet } from "react-helmet";

const Videoteca = () => {
    const [open, setOpen] = useState(false);
    const node = useRef();
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        async function fetchData() {
            // Realizar una solicitud a la API de YouTube para obtener los datos de búsqueda
            const response = await fetch(
                'https://www.googleapis.com/youtube/v3/search?key=AIzaSyDZAQt6W2Pq5kNYoccaJhDYK5BgsgxG180&channelId=UC9iAcyLemH-fPVnuQpADF2Q&part=snippet,id&order=date&maxResults=1000'
            );
            const data = await response.json();

            // Extraer los videoIds de los resultados de búsqueda
            const videoIds = data.items.map((item) => item.id.videoId);

            // Establecer los videoIds en el estado
            setVideos(videoIds);
        }
        fetchData();
    }, []);

    useOnClickOutside(node, () => setOpen(false));

    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(9);


    // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = videos.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

    return (
        <ThemeProvider theme={theme}>
            <Helmet>
                <title>César Acuña Peralta - Videoteca </title>
                <meta name="description"
                    content="Como muchos peruanos, César Acuña estaba más cerca de un destino de pobreza, de informalidad, de desigualdad, que de éxito. Sin embargo, se sobrepuso a ello. Y se convirtió en un empresario exitoso, fundó un partido político, fue alcalde, fue gobernador, y ocupó puestos de relevancia en política." />
                <meta property="og:title" content="César Acuña Peralta  - Videoteca  " />
                <meta property="og:image" content="%PUBLIC_URL%/cap-foto.png" />
                <meta property="og:url" content="https://cesaracuna.pe" />
            </Helmet>
            <GlobalStyles />
            <section id='videoteca'>
                <Cabecera titulo={"Videoteca"}></Cabecera>
                <div className='body-page'>

                    <div className='container'>
                        <Videos posts={currentPosts} loading={loading} />
                        <Pagination
                            postsPerPage={postsPerPage}
                            totalPosts={videos.length}
                            paginate={paginate}
                        />
                    </div>
                </div>
            </section>
            <Footer />
            <div ref={node}>
                <Burger open={open} setOpen={setOpen} />
                <Menu open={open} setOpen={setOpen} />
            </div>
        </ThemeProvider>
    );

}

export default Videoteca;
