// Burger.styled.js
import styled from 'styled-components';
export const StyledOver = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.78);
  z-index: 10000;

  display:${({ openModal }) => openModal ? 'block' : 'none !important'};
  &:focus {
    outline: none;
  }

  div {
    width: 2.2rem;
    height: 0.2rem;
    background: ${({ theme }) => theme.white};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    
    .img-over {
      height: 80%;
      object-fit: scale-down;
      width: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
  }
`;

//background: ${({ theme, open }) => open ? theme.primaryLight : theme.primaryDark};