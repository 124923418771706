// SocialNetwork.styled.js
import styled from 'styled-components';
export const StyledSocialNetwork = styled.nav`
    width: 100%;
    position: absolute;
    bottom: 135px;
    display: flex;

    ul {
        margin: 0 auto;
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: row;

        li {
            width: 44px;
            height: 44px;
            display: flex;
            justify-content: center;
            text-align: center;
            border-radius: 50%;
            background: ${({ theme }) => theme.gris};
            color: #E0E0E0;
            margin: 0 5px;
            line-height: 44px;
            text-align: center;
            overflow: hidden;

            a {
                width: 100%;
                color: #E0E0E0;
                text-decoration: none;
                transition: all .3s ease-in-out;

                &:hover {
                    background: #E0E0E0;
                    color: ${({ theme }) => theme.gris};
                }
            }
        }
    }
`;