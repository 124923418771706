import React from "react";
import PropTypes, { bool, func } from "prop-types";
import { StyledOver } from './Over.styled';
import './ModalZoom.css'
const noteimg = require.context('../../images/gallery')

const ModalZoom = ({ image, openModal, setOpenModal }) => {
  return (
    <StyledOver className="over" openModal={openModal} onClick={() => setOpenModal(!openModal)}>
      <img className="img-over " src={noteimg('./' + image)} alt="note" loading="lazy" />
    </StyledOver>
  )
}

ModalZoom.propTypes = {
  image: PropTypes.string,
  openModal: bool.isRequired,
  setOpenModal: func.isRequired,
}
export default ModalZoom