import { useState } from 'react';

const VerMas = ({ contenidoHtml }) => {
  const [mostrarContenido, setMostrarContenido] = useState(false);

  const handleClick = () => {
    setMostrarContenido(!mostrarContenido);
  };
  let textoString = contenidoHtml.split(',').join('&#44;').split('<br/><br/>').slice(1, 20).toString()
  return (
    <div>
      {mostrarContenido ? (
        <>
          <div className='text-center mb-2' dangerouslySetInnerHTML={{ __html: textoString.split(',').join('<br/><br/>') }} />
          <span onClick={handleClick} className="px-3 py-2"
            style={{ cursor: 'pointer', backgroundColor: '#464aff', color: '#f2f4f5', borderRadius: '0.5em', marginTop: '0.5em' }}> Ver menos</span>
        </>
      ) : (
        <span onClick={handleClick} className="px-3 py-2" style={{
          cursor: 'pointer',
          backgroundColor: '#464aff',
          color: '#f2f4f5',
          borderRadius: '0.5em'
        }}> Ver más</span>
      )}
    </div>
  );
}

export default VerMas;