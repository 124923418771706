import React from 'react';
const noteimg = require.context('../../images/blog')

const Videos = ({ posts, loading }) => {
  if (loading) {
    return <h2>Loading...</h2>;
  }

  return (
    <div className='d-flex  justify-content-center flex-wrap flex-1'>
      {posts.map((videoId, snippet) => (
        <div className='col-sm-12 col-md-6 col-lg-4 px-1 mb-5 d-flex text-center  overflow-hidden' key={videoId}><iframe className='cardVideo' width="100%" height="315" src={'https://www.youtube.com/embed/' + videoId} title={snippet.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style={{ margin: "0 auto", }}></iframe></div>
      ))}
    </div>
  );
};

export default Videos;
