import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from '../../global/Global';
import { theme } from '../../theme/theme';
import React, { useState, useEffect, useRef } from 'react';
import { useOnClickOutside } from '../../hooks/hooks';

import Cabecera from '../../components/Cabecera/Cabecera';
import Burger from '../../components/Burger/Burger';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import Pagination from '../../components/Pagination/Pagination'
import Posts from '../../components/Posts/Posts';

import json from './notas.json'
import './MenuPrensa.css'
import { Helmet } from "react-helmet";

function MenuPrensa() {

  const [open, setOpen] = useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setOpen(false));

  json.sort((a, b) => {
    return a.date.split('-').reverse().join().localeCompare(b.date.split('/').reverse().join());
  })

  let [filtro, setFiltro] = useState('todo');


  const [posts, setPosts] = useState(json);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(9);


  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>César Acuña Peralta - Prensa </title>
        <meta name="description"
          content="Como muchos peruanos, César Acuña estaba más cerca de un destino de pobreza, de informalidad, de desigualdad, que de éxito. Sin embargo, se sobrepuso a ello. Y se convirtió en un empresario exitoso, fundó un partido político, fue alcalde, fue gobernador, y ocupó puestos de relevancia en política." />
        <meta property="og:title" content="César Acuña Peralta  - Prensa  " />
        <meta property="og:image" content="%PUBLIC_URL%/cap-foto.png" />
        <meta property="og:url" content="https://cesaracuna.pe/prensa" />
      </Helmet>
      <GlobalStyles />
      <section id="prensa">
        <Cabecera titulo={"Prensa"} />
        <div className='body-page'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-sm-12 col-md-6'>
                <div className='cap-foto'>
                </div>
              </div>
              <div className='container p-5'>
                <div className="filter pb-5 d-block"
                  style={{ color: '#a8a8a8', fontFamily: 'GothamBook' }}>
                  <span>FILTRAR POR:</span>
                  <button
                    className={filtro === 'todo' ? 'text-primary border border-primary rounded p-1 mx-2 active' : 'text-primary border border-primary rounded p-1 mx-2'}
                    onClick={() => {
                      setPosts(json)
                      setFiltro('todo')
                    }}>
                    TODO
                  </button>
                  <button
                    className={filtro === 'video' ? 'text-primary border border-primary rounded p-1 mx-2 active' : 'text-primary border border-primary rounded p-1 mx-2'}
                    onClick={() => {
                      setPosts(json.filter(json => json.type === 'video'))
                      setFiltro('video')
                    }}>
                    VIDEOS
                  </button>
                  <button
                    className={filtro === 'nota' ? 'text-primary border border-primary rounded p-1 mx-2 active' : 'text-primary border border-primary rounded p-1 mx-2'}
                    onClick={() => {
                      setPosts(json.filter(json => json.type === 'nota'))
                      setFiltro('nota')
                    }}>
                    NOTA
                  </button>
                </div>
                <div className='container'>
                  <Posts posts={currentPosts} loading={loading} />
                  <Pagination
                    postsPerPage={postsPerPage}
                    totalPosts={posts.length}
                    paginate={paginate}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <div ref={node}>
        <Burger open={open} setOpen={setOpen} />
        <Menu open={open} setOpen={setOpen} />
      </div>
    </ThemeProvider>
  );
}

export default MenuPrensa;