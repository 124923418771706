import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from '../../global/Global';
import { theme } from '../../theme/theme';
import Burger from '../../components/Burger/Burger';
import Menu from '../../components/Menu/Menu';
import React, { useState, useRef } from 'react';
import { useOnClickOutside } from '../../hooks/hooks';
import VideoBackground from '../../components/VideoBackground/VideoBackground';
import { Helmet } from "react-helmet";

const Home = () => {
  const [open, setOpen] = useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setOpen(false));

  return (
    <ThemeProvider theme={theme}><Helmet>
      <title>César Acuña Peralta </title>
      <meta name="description"
        content="Como muchos peruanos, César Acuña estaba más cerca de un destino de pobreza, de informalidad, de desigualdad, que de éxito. Sin embargo, se sobrepuso a ello. Y se convirtió en un empresario exitoso, fundó un partido político, fue alcalde, fue gobernador, y ocupó puestos de relevancia en política." />
      <meta property="og:title" content="César Acuña Peralta" />
      <meta property="og:image" content="%PUBLIC_URL%/cap-foto.png" />
      <meta property="og:url" content="https://cesaracuna.pe" />
    </Helmet>
      <GlobalStyles />
      <VideoBackground id="inicio" className='page-container' />
      <div ref={node}>
        <Burger open={open} setOpen={setOpen} />
        <Menu open={open} setOpen={setOpen} />
      </div>
    </ThemeProvider>
  );
}

export default Home;













