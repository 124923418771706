// VideoBackground.styled.js
import styled from 'styled-components';
import fondodesck from '../../video/video_1280x720.gif';
import fondotablet from '../../video/video_960x1980.gif';
import fondo from '../../video/video_480x720.gif';

export const StyledVideoBackground = styled.div`
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  .video-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${fondo});

    @media (min-width: 768px) {

      background-image: url(${fondotablet});
    }

    @media (min-width: 1200px) {

      background-image: url(${fondodesck});
    }
  }

  .video-foreground {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
  }

  .video-background video {
    display: none;
    width: 0;
    height: 0;
    left: 0;
    top: 0;
    
	  @media (min-width: 768px) {
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
      object-fit: cover;
    }
  }
`;