import './App.css';
import './fonts.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Pages
import Home from './views/Home/Home';
import Biografia from './views/Biografia/Biografia';
import Videoteca from './views/Videoteca/Videoteca';
import Fundacion from './views/Fundacion/Fundacion';
import Prensa from './views/Prensa/Prensa';
import MenuPrensa from './views/MenuPrensa/MenuPrensa';
// import RedesSociales from './views/RedesSociales/RedesSociales';
import MisObras from './views/MisObras/MisObras';
import NotFoundPage from './views/NotFoundPage/NotFoundPage';
// import Contacto from './views/Contacto/Contacto';

const App = () => {
  return (
    <BrowserRouter>
      <div className='w-100 h-100 fondo-menu'></div>
      <div className='w-100 fondo-cabecera'></div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/biografia" element={<Biografia />} />
        <Route path="/fundacion" element={<Fundacion />} />
        <Route path="/prensa" element={<MenuPrensa />} />
        <Route path="/prensa/:slug" element={<Prensa />} />
        <Route path="/mis-obras" element={<MisObras />} />
        {/*<Route path="/redes-sociales" element={<RedesSociales />} />*/}
        <Route path="/videoteca" element={<Videoteca />} />
        {/*<Route path="/contacto" element={<Contacto />} />*/}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
