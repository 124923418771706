import React from 'react';
const noteimg = require.context('../../images/blog')

const Posts = ({ posts, loading }) => {
  if (loading) {
    return <h2>Loading...</h2>;
  }

  return (
    <div className='d-flex  justify-content-center flex-wrap flex-1'>
      {posts.map(post => (
        <a href={'/prensa/' + post.slug}

          className="card mx-3 rounded-2 shadow bg-body-tertiary rounded overflow-hidden ">
          <img className="w-100 " src={noteimg('./' + post.img)} alt={post.title}
            title={post.title} loading="lazy" />
          {post.type === 'video' && <div className="btn-play"><span></span></div>}
          <div className="p-3">
            <div className="fecha-card fs-6 text-start mb-2"
              style={{ color: '#a8a8a8', fontFamily: 'GothamBook' }}>{post.date}
            </div>
            <h3 className="titulo-card fs-4 text-start "
              style={{
                color: '#464AFF',
                fontFamily: 'GothamBook'
              }}>{post.title}</h3>
            <p className="parrafo-card fs-6 text-start "
              style={{
                color: '#181E20',
                fontFamily: 'GothamBook'
              }} dangerouslySetInnerHTML={{ __html: post.paragraf }}></p>
          </div>
        </a>
      ))}
    </div>
  );
};

export default Posts;
