import React from 'react';
import { StyledObras } from './Obras.styled';
import readObras from './obras.json';
import "./Obras.css"
import VerMas from "../VerMasObras/VerMas";

const noteimg = require.context('../../images/works');

const Obras = () => {
    return (
        <StyledObras>
            {readObras.map(obra => (
                <div className='row'>
                    {((obra.id % 2) === 0) ?
                        <div className='container-line'>
                            <h2 className='date a'>{obra.year}</h2>
                            <div className="line">
                                <div className="point"></div>
                                <div className="point2"></div>
                            </div>
                            <div className="cont-img">
                                <img className='img-line' src={noteimg('./' + obra.image)} alt={obra.title}
                                    title={obra.title} loading="lazy" />
                                <div className="cont-text a">
                                    <h4>{obra.year}</h4>
                                    <h3>{obra.title}</h3>
                                    <p dangerouslySetInnerHTML={{ __html: obra.content.split('<br/><br/>').slice(0, 1) }}></p>
                                    <VerMas contenidoHtml={obra.content}></VerMas>
                                </div>
                            </div>
                        </div>
                        :
                        <div className='container-line'>
                            <div className="cont-img">
                                <img className='img-line' src={noteimg('./' + obra.image)} alt={obra.title}
                                    title={obra.title} loading="lazy" />
                                <div className="cont-text b">
                                    <h4>{obra.year}</h4>
                                    <h3>{obra.title}</h3>
                                    <p dangerouslySetInnerHTML={{ __html: obra.content.split('<br/><br/>').slice(0, 1) }}></p>
                                    <VerMas contenidoHtml={obra.content}></VerMas>
                                </div>
                            </div>
                            <div className="line">
                                <div className="point"></div>
                                <div className="point2"></div>
                            </div>
                            <h2 className='date b'>{obra.year}</h2>
                        </div>}
                </div>
            ))}
        </StyledObras>
    );
}

export default Obras;