import React from "react";
import Slider from "react-slick";
import './CarruselFundacion.css'
import json from './fundacion.json'

const noteimg = require.context('../../images/icons')

export default function CarruselFundacion() {
  var settings = {
    centerMode: true,
    centerPadding: '100px',
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      }
    ]
  };
  return (
    <Slider {...settings}>
      {json.map(json => (
        <>
          <div className="card-fundacion col m-3 p-3 text-center">
            <div className="text-center">
              <img className='img-f' style={{}}
                src={noteimg('./' + json.img)}
                title={json.title} alt={json.title} loading="lazy" /></div>
            <h5 className="subtitle" style={{ margin: '0 0 20px' }}>{json.title}</h5>
            <p className='parrafo-f' dangerouslySetInnerHTML={{ __html: json.paragraf }}></p>
          </div>
        </>
      ))}
    </Slider>
  );
}